'use strict';

var Css = require("bs-css/src/Css.js");

function _840(rules) {
  return Css.media("(max-width: 840px)", rules);
}

exports._840 = _840;
/* Css Not a pure module */
