'use strict';

var Css = require("bs-css/src/Css.js");

var primary100 = Css.hex("202020");

var primary200 = Css.hex("404040");

var primary300 = Css.hex("808080");

var primary400 = Css.hex("c0c0c0");

var primary450 = Css.hex("e0e0e0");

var primary475 = Css.hex("f0f0f0");

var primary490 = Css.hex("f8f8f8");

var primary500 = Css.hex("ffffff");

exports.primary100 = primary100;
exports.primary200 = primary200;
exports.primary300 = primary300;
exports.primary400 = primary400;
exports.primary450 = primary450;
exports.primary475 = primary475;
exports.primary490 = primary490;
exports.primary500 = primary500;
/* primary100 Not a pure module */
