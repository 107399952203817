'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var React = require("react");
var Js_mapperRt = require("bs-platform/lib/js/js_mapperRt.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");

var body = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.lineHeight(Css.px(22)),
        /* [] */0
      ]
    ]);

var tiny = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary400),
      /* :: */[
        Css.fontSize(Css.px(11)),
        /* :: */[
          Css.lineHeight(Css.px(14)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  body: body,
  tiny: tiny
};

var monoTitle = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.fontFamily(Fonts$Paulshen.mono),
        /* :: */[
          Css.textTransform(Css.lowercase),
          /* [] */0
        ]
      ]
    ]);

var size1 = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(Css.normal),
        /* :: */[
          Css.letterSpacing(Css.pxFloat(0.5)),
          /* :: */[
            Css.marginBottom(Css.px(8)),
            /* [] */0
          ]
        ]
      ]
    ]);

var size2 = Css.style(/* :: */[
      Css.fontSize(Css.px(20)),
      /* :: */[
        Css.fontWeight(Css.normal),
        /* :: */[
          Css.letterSpacing(Css.pxFloat(0.5)),
          /* :: */[
            Css.marginBottom(Css.px(16)),
            /* [] */0
          ]
        ]
      ]
    ]);

var size3 = Css.style(/* :: */[
      Css.fontSize(Css.px(28)),
      /* :: */[
        Css.fontWeight(Css.normal),
        /* :: */[
          Css.letterSpacing(Css.pxFloat(0.5)),
          /* :: */[
            Css.marginBottom(Css.px(16)),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles$1 = {
  monoTitle: monoTitle,
  size1: size1,
  size2: size2,
  size3: size3
};

var jsMapperConstantArray = /* array */[
  /* tuple */[
    16105,
    "h1"
  ],
  /* tuple */[
    16106,
    "h2"
  ],
  /* tuple */[
    16107,
    "h3"
  ],
  /* tuple */[
    16108,
    "h4"
  ]
];

function tagToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray);
}

function tagFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray, param);
}

function Text$MonoHeader(Props) {
  var children = Props.children;
  var match = Props.size;
  var size = match !== undefined ? match : /* S1 */18558;
  var match$1 = Props.tag;
  var tag = match$1 !== undefined ? match$1 : /* H2 */16106;
  var className = Props.className;
  return React.createElement(Js_mapperRt.binarySearch(4, tag, jsMapperConstantArray), {
              className: Cn.make(/* :: */[
                    monoTitle,
                    /* :: */[
                      size !== 18559 ? (
                          size >= 18560 ? size3 : size1
                        ) : size2,
                      /* :: */[
                        Cn.unpack(className),
                        /* [] */0
                      ]
                    ]
                  ])
            }, children);
}

var MonoHeader = {
  Styles: Styles$1,
  tagToJs: tagToJs,
  tagFromJs: tagFromJs,
  make: Text$MonoHeader
};

var header = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary200),
      /* :: */[
        Css.fontWeight(Css.normal),
        /* [] */0
      ]
    ]);

var size1$1 = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.marginBottom(Css.px(6)),
        /* [] */0
      ]
    ]);

var size2$1 = Css.style(/* :: */[
      Css.fontSize(Css.px(20)),
      /* :: */[
        Css.marginBottom(Css.px(16)),
        /* [] */0
      ]
    ]);

var size3$1 = Css.style(/* :: */[
      Css.fontSize(Css.px(28)),
      /* :: */[
        Css.fontWeight(Css.medium),
        /* :: */[
          Css.marginBottom(Css.px(24)),
          /* [] */0
        ]
      ]
    ]);

var Styles$2 = {
  header: header,
  size1: size1$1,
  size2: size2$1,
  size3: size3$1
};

var jsMapperConstantArray$1 = /* array */[
  /* tuple */[
    16105,
    "h1"
  ],
  /* tuple */[
    16106,
    "h2"
  ],
  /* tuple */[
    16107,
    "h3"
  ],
  /* tuple */[
    16108,
    "h4"
  ]
];

function tagToJs$1(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray$1);
}

function tagFromJs$1(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray$1, param);
}

function Text$Header(Props) {
  var children = Props.children;
  var match = Props.size;
  var size = match !== undefined ? match : /* S1 */18558;
  var match$1 = Props.tag;
  var tag = match$1 !== undefined ? match$1 : /* H2 */16106;
  var className = Props.className;
  return React.createElement(Js_mapperRt.binarySearch(4, tag, jsMapperConstantArray$1), {
              className: Cn.make(/* :: */[
                    header,
                    /* :: */[
                      size !== 18559 ? (
                          size >= 18560 ? size3$1 : size1$1
                        ) : size2$1,
                      /* :: */[
                        Cn.unpack(className),
                        /* [] */0
                      ]
                    ]
                  ])
            }, children);
}

var Header = {
  Styles: Styles$2,
  tagToJs: tagToJs$1,
  tagFromJs: tagFromJs$1,
  make: Text$Header
};

function Text$Body(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    body,
                    /* :: */[
                      Cn.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, children);
}

var Body = {
  make: Text$Body
};

function Text$Tiny(Props) {
  var children = Props.children;
  var className = Props.className;
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    tiny,
                    /* :: */[
                      Cn.unpack(className),
                      /* [] */0
                    ]
                  ])
            }, children);
}

var Tiny = {
  make: Text$Tiny
};

exports.Styles = Styles;
exports.MonoHeader = MonoHeader;
exports.Header = Header;
exports.Body = Body;
exports.Tiny = Tiny;
/* body Not a pure module */
