'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Gatsby = require("gatsby");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var SEO$Paulshen = require("../components/SEO.bs.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");

var page = Css.style(/* :: */[
      Css.width(Css.pct(90)),
      /* :: */[
        Css.margin2(Css.zero, Css.auto),
        /* :: */[
          Css.maxWidth(Css.px(1400)),
          /* :: */[
            Css.paddingTop(Css.px(84)),
            /* :: */[
              Css.paddingBottom(Css.px(128)),
              /* :: */[
                MediaQuery$Paulshen._840(/* :: */[
                      Css.paddingTop(Css.px(64)),
                      /* :: */[
                        Css.paddingBottom(Css.px(64)),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var header = Css.style(/* :: */[
      Css.position(Css.fixed),
      /* :: */[
        Css.top(Css.zero),
        /* :: */[
          Css.left(Css.zero),
          /* :: */[
            Css.right(Css.zero),
            /* :: */[
              Css.padding2(Css.px(24), Css.zero),
              /* :: */[
                Css.backgroundColor(Css.rgba(255, 255, 255, 0)),
                /* :: */[
                  Css.zIndex(1),
                  /* :: */[
                    Css.transition(200, undefined, undefined, "background-color"),
                    /* :: */[
                      Css.hover(/* :: */[
                            Css.backgroundColor(Css.rgba(255, 255, 255, 0.9)),
                            /* [] */0
                          ]),
                      /* :: */[
                        MediaQuery$Paulshen._840(/* :: */[
                              Css.padding2(Css.px(16), Css.zero),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var headerContents = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.alignItems(Css.center),
        /* :: */[
          Css.justifyContent(Css.spaceBetween),
          /* :: */[
            Css.width(Css.pct(90)),
            /* :: */[
              Css.maxWidth(Css.px(1400)),
              /* :: */[
                Css.margin2(Css.zero, Css.auto),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var headerFull = Css.style(/* :: */[
      Css.backgroundColor(Css.rgba(255, 255, 255, 0.93)),
      /* [] */0
    ]);

var headerName = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary400),
      /* :: */[
        Css.fontWeight(Css.normal),
        /* :: */[
          Css.textDecoration(Css.none),
          /* :: */[
            Css.hover(/* :: */[
                  Css.color(Colors$Paulshen.primary100),
                  /* :: */[
                    Css.textDecoration(Css.underline),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var headerNav = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.opacity(0),
        /* :: */[
          Css.transition(200, undefined, undefined, "opacity"),
          /* :: */[
            MediaQuery$Paulshen._840(/* :: */[
                  Css.display(Css.none),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var headerNavLink = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.display(Css.inlineBlock),
        /* :: */[
          Css.fontFamily(Fonts$Paulshen.mono),
          /* :: */[
            Css.fontWeight(Css.light),
            /* :: */[
              Css.fontSize(Css.px(14)),
              /* :: */[
                Css.textTransform(Css.lowercase),
                /* :: */[
                  Css.letterSpacing(Css.pxFloat(0.5)),
                  /* :: */[
                    Css.marginLeft(Css.px(16)),
                    /* :: */[
                      Css.textDecoration(Css.none),
                      /* :: */[
                        Css.hover(/* :: */[
                              Css.color(Colors$Paulshen.primary100),
                              /* :: */[
                                Css.textDecoration(Css.underline),
                                /* [] */0
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

Css.$$global("." + (header + (":hover ." + headerNav)), /* :: */[
      Css.opacity(1),
      /* [] */0
    ]);

Css.$$global("." + (headerFull + (" ." + headerNav)), /* :: */[
      Css.opacity(1),
      /* [] */0
    ]);

var Styles = {
  page: page,
  header: header,
  headerContents: headerContents,
  headerFull: headerFull,
  headerName: headerName,
  headerNav: headerNav,
  headerNavLink: headerNavLink
};

function Page$Header(Props) {
  var match = React.useState((function () {
          return true;
        }));
  var setShowFull = match[1];
  var showFull = match[0];
  React.useEffect((function () {
          var lastPageYOffset = {
            contents: window.pageYOffset
          };
          var showFull$1 = {
            contents: showFull
          };
          var onScroll = function (param) {
            var pageYOffset = window.pageYOffset;
            var shouldShowFull = pageYOffset <= 0 || pageYOffset < lastPageYOffset.contents;
            if (showFull$1.contents !== shouldShowFull) {
              showFull$1.contents = shouldShowFull;
              Curry._1(setShowFull, (function (param) {
                      return shouldShowFull;
                    }));
            }
            lastPageYOffset.contents = pageYOffset;
            return /* () */0;
          };
          window.addEventListener("scroll", onScroll);
          return (function (param) {
                    window.removeEventListener("scroll", onScroll);
                    return /* () */0;
                  });
        }), ([]));
  return React.createElement("div", {
              className: Cn.make(/* :: */[
                    header,
                    /* :: */[
                      Cn.ifTrue(headerFull, showFull),
                      /* [] */0
                    ]
                  ])
            }, React.createElement("div", {
                  className: headerContents
                }, React.createElement("div", undefined, React.createElement(Gatsby.Link, {
                          to: "/",
                          children: "paul shen",
                          className: headerName
                        })), React.createElement("div", {
                      className: headerNav
                    }, React.createElement(Gatsby.Link, {
                          to: "/",
                          children: "/posts",
                          className: headerNavLink
                        }), React.createElement(Gatsby.Link, {
                          to: "/drawings",
                          children: "/drawings",
                          className: headerNavLink
                        }), React.createElement(Gatsby.Link, {
                          to: "/photos",
                          children: "/photos",
                          className: headerNavLink
                        }), React.createElement(Gatsby.Link, {
                          to: "/about",
                          children: "/about",
                          className: headerNavLink
                        }))));
}

var Header = {
  make: Page$Header
};

function Page(Props) {
  var children = Props.children;
  var title = Props.title;
  var description = Props.description;
  var match = Props.header;
  var header = match !== undefined ? match : true;
  var tmp = { };
  if (title !== undefined) {
    tmp.title = Caml_option.valFromOption(title);
  }
  if (description !== undefined) {
    tmp.description = Caml_option.valFromOption(description);
  }
  return React.createElement("div", {
              className: page
            }, React.createElement(SEO$Paulshen.make, tmp), header ? React.createElement(Page$Header, { }) : null, children);
}

var make = Page;

exports.Styles = Styles;
exports.Header = Header;
exports.make = make;
/* page Not a pure module */
