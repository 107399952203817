'use strict';

var React = require("react");
var ReactHelmet = require("react-helmet");

var Helmet = { };

function SEO(Props) {
  var title = Props.title;
  var description = Props.description;
  return React.createElement(ReactHelmet.default, {
              title: (
                title !== undefined ? title + " | " : ""
              ) + "Paul Shen",
              meta: description !== undefined ? /* array */[{
                    name: "description",
                    property: undefined,
                    content: description
                  }] : /* array */[]
            });
}

var make = SEO;

var $$default = SEO;

exports.Helmet = Helmet;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
